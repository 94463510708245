import * as React from 'react'
import './App.css'
import {
  FormControlLabel,
  FormGroup,
  Paper,
  Stack,
  Switch,
  TextField,
  Typography,
  Unstable_Grid2,
} from '@mui/material'
import { useState } from 'react'
import Tattoo from './Tattoo'
import Footer from './Footer'
import CirclePattern from './CirclePattern'

function App() {
  const [address, setAddress] = useState(
    '0x7156156e73835176d37922b071c80d6cC722A943',
  )
  const [tokenId, setTokenId] = useState(0)
  const [svgWidth, setSvgWidth] = useState(1500)
  const [svgHeight, setSvgHeight] = useState(500)
  const [numberOfChars, setNumberOfChars] = useState(5)
  const [numberOfCellsHorizontally, setNumberOfCellsHorizontally] = useState(
    150,
  )
  const [darkMode, setDarkMode] = useState(false)
  const [path, setPath] = useState(true)
  const [textPath, setTextPath] = useState(false)
  const [pacman, setPacman] = useState(false)
  const [filter, setFilter] = useState(false)
  const [grid, setGrid] = useState(false)

  return (
    <Stack spacing={4} sx={{ my: 4 }}>
      <Paper sx={{ py: 4, px: 2 }}>
        <Typography variant="h3">On-Chain Tattoo Test Page</Typography>
      </Paper>

      <Paper sx={{ p: 2 }}>
        <Unstable_Grid2 container spacing={2}>
          <Unstable_Grid2 xs={12}>
            <Typography variant="h4">Smart Contract Inputs</Typography>
          </Unstable_Grid2>
          <Unstable_Grid2 xs={6}>
            <TextField
              variant="outlined"
              fullWidth
              value={address}
              label="Address"
              onChange={(event) => {
                setAddress(event.target.value)
              }}
            />
          </Unstable_Grid2>
          <Unstable_Grid2 xs={6}>
            <TextField
              variant="outlined"
              value={tokenId}
              label="Token ID"
              type="number"
              onChange={(event) => {
                setTokenId(parseInt(event.target.value))
              }}
            />
          </Unstable_Grid2>
        </Unstable_Grid2>
      </Paper>

      <Paper sx={{ p: 2 }}>
        <Unstable_Grid2 container spacing={2}>
          <Unstable_Grid2 xs={12}>
            <Typography variant="h4">Other Inputs</Typography>
          </Unstable_Grid2>

          <Unstable_Grid2 xs={6}>
            <Stack spacing={2}>
              <TextField
                variant="outlined"
                value={svgWidth}
                label="SVG Width"
                type="number"
                onChange={(event) => {
                  setSvgWidth(parseInt(event.target.value))
                }}
              />
              <TextField
                variant="outlined"
                value={svgHeight}
                label="SVG Height"
                type="number"
                onChange={(event) => {
                  setSvgHeight(parseInt(event.target.value))
                }}
              />
            </Stack>
          </Unstable_Grid2>
          <Unstable_Grid2 xs={6}>
            <Stack spacing={2}>
              <TextField
                variant="outlined"
                value={numberOfChars}
                label="Number of characters"
                type="number"
                onChange={(event) => {
                  setNumberOfChars(parseInt(event.target.value))
                }}
              />
              <TextField
                variant="outlined"
                value={numberOfCellsHorizontally}
                label="Number of horizontal cells"
                type="number"
                onChange={(event) => {
                  setNumberOfCellsHorizontally(parseInt(event.target.value))
                }}
              />

              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={grid}
                      onChange={(e) => {
                        setGrid(e.target.checked)
                      }}
                    />
                  }
                  label="Grid"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={darkMode}
                      onChange={(e) => {
                        setDarkMode(e.target.checked)
                      }}
                    />
                  }
                  label="Darkmode"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={path}
                      onChange={(e) => {
                        setPath(e.target.checked)
                      }}
                    />
                  }
                  label="Animated path"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={textPath}
                      onChange={(e) => {
                        setTextPath(e.target.checked)
                      }}
                    />
                  }
                  label="Animated text path"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={filter}
                      onChange={(e) => {
                        setFilter(e.target.checked)
                      }}
                    />
                  }
                  label="Filter"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={pacman}
                      onChange={(e) => {
                        setPacman(e.target.checked)
                      }}
                    />
                  }
                  label="Pac Man"
                />
              </FormGroup>
            </Stack>
          </Unstable_Grid2>
        </Unstable_Grid2>
      </Paper>

      <Paper sx={{ p: 2 }}>
        <Stack spacing={2}>
          <Typography variant="h2">Tattoo Test</Typography>

          <Tattoo
            width={svgWidth}
            height={svgHeight}
            tokenId={tokenId}
            address={address}
            numberOfChars={numberOfChars}
            numberOfCellsHorizontally={numberOfCellsHorizontally}
            darkMode={darkMode}
            path={path}
            textPath={textPath}
            filter={filter}
            pacman={pacman}
            grid={grid}
          />

          <CirclePattern
            width={svgWidth}
            height={svgHeight}
            tokenId={tokenId}
            address={address}
            numberOfChars={numberOfChars}
            numberOfCellsHorizontally={numberOfCellsHorizontally}
            darkMode={darkMode}
            filter={filter}
            grid={grid}
          />
        </Stack>
      </Paper>
      <Footer />
    </Stack>
  )
}

export default App
