import Random from './Random'

class Cell {
  row
  col
  visited = false
  goal = false
  walls = {
    north: true,
    east: true,
    south: true,
    west: true,
  }

  constructor(row, col) {
    this.row = row
    this.col = col
  }
}

export default class Maze {
  rows = []
  randomSeed
  solution = []
  solved = false
  rnd

  constructor(numberOfRows, numberOfCols, randomSeed) {
    for (let r=0; r<numberOfRows; r++) {
      const row = []
      for (let c=0; c<numberOfCols; c++) {
        row.push(new Cell(r, c))
      }
      this.rows.push(row)
    }

    this.rows[0][0].walls.west = false
    this.rows[this.rows.length-1][this.rows[0].length-1].walls.east = false
    this.rows[this.rows.length-1][this.rows[0].length-1].goal = true

    this.rnd = new Random(randomSeed, 2, 0b11)
  }

  get numberOfRows() {
    return this.rows.length
  }

  get numberOfCols() {
    return this.rows[0].length
  }  

  visit(row, col) {
    this.rows[row][col].visited = true
  }

  removeWall(cell1, cell2) {
    const rowDifference = cell1.row - cell2.row
    const colDifference = cell1.col - cell2.col
    
    switch(rowDifference) {
      case 1:
        cell1.walls.north = false
        cell2.walls.south = false
        break;
      case -1: 
        cell1.walls.south = false
        cell2.walls.north = false
        break;
      default:
    }

    switch(colDifference) {
      case 1:
        cell1.walls.west = false
        cell2.walls.east = false
        break;
      case -1: 
        cell1.walls.east = false
        cell2.walls.west = false
        break;
      default:
    }
  }

  getCell(row, col) {
    return this.rows[row][col]
  }

  printVisited() {
    let indexes = '  |'
    let dashes  = '--+'

    for (let i=0; i<this.rows[0].length; i++) {
      indexes += ` ${i}`
      dashes += '--'
    }

    console.log(indexes)
    console.log(dashes)

    for (let r=0; r<this.rows.length; r++) {
      let rowString = `${r} |`
      for (let c=0; c<this.rows[r].length; c++) {
        rowString = `${rowString} ${this.rows[r][c].visited ? 'x' : '-'}`
      }
      console.log(rowString)
    }

  }

  printWalls() {
    for (let r=0; r<this.rows.length; r++) {

      let topRow = ''
      let middleRow = ''
      let bottomRow = ''

      for (let c=0; c<this.rows[r].length; c++) {

        topRow += this.rows[r][c].walls.north ? '+--+' : '+  +'
        middleRow += this.rows[r][c].walls.west ? '|' : ' '
        middleRow += '  '
        middleRow += this.rows[r][c].walls.east ? '|' : ' '
        bottomRow += this.rows[r][c].walls.south ? '+--+' : '+  +'
      }
      console.log(topRow)
      console.log(middleRow)
      console.log(bottomRow)
    }
  }

  neighboursToString(cell, neighbours) {
    const reducedArray = neighbours.map(e => { return `(${e.row}, ${e.col})`})
    return `[${cell.row}, ${cell.col}]: ${reducedArray}`
  }

  getUnvisitedNeighbours(cell) {
    let neighbours = []

    neighbours.push(cell.row - 1 >= 0 && !this.getCell(cell.row-1, cell.col).visited ? this.getCell(cell.row-1, cell.col) : undefined)
    neighbours.push(cell.row + 1 < this.rows.length && !this.getCell(cell.row+1, cell.col).visited ? this.getCell(cell.row+1, cell.col) : undefined)
    neighbours.push(cell.col - 1 >= 0 && !this.getCell(cell.row, cell.col-1).visited ? this.getCell(cell.row, cell.col-1) : undefined)
    neighbours.push(cell.col + 1 < this.rows[0].length && !this.getCell(cell.row, cell.col+1).visited ? this.getCell(cell.row, cell.col+1) : undefined)

    return neighbours.filter(n => n !== undefined)
  }

  getRandomNeighbour(neighbours) {
    if (neighbours.length === 0) {
      return null
    }

    let randomValue = this.rnd.getRandomNumber()
    // pick rnd value
    while (randomValue > neighbours.length-1) {
      randomValue = this.rnd.getRandomNumber()
    } 
    
    // assign next
    return neighbours[randomValue]
  }

  randomizeDFS(cell) {
    // console.log(this.solution.map(c => { return `${c.row}/${c.col}`}))
    cell.visited = true
    cell.goal && (this.solved = true)
    cell.goal && this.solution.push(cell)
    
    !this.solved && this.solution.push(cell)
    let neighbours = this.getUnvisitedNeighbours(cell)
    let next = this.getRandomNeighbour(neighbours)
    
    while (next !== null) {
      this.removeWall(cell, next)
      this.randomizeDFS(next)
      neighbours = this.getUnvisitedNeighbours(cell)
      next = this.getRandomNeighbour(neighbours)
    }
    !this.solved && this.solution.pop()

  }
}

// const g = new Graph(10, 10)
// g.visit(0,0)
// // g.visit(5,2)
// // g.removeWall(g.getCell(0,0), g.getCell(1,0))
// // g.removeWall(g.getCell(0,1), g.getCell(0,2))
// // g.removeWall(g.getCell(0,4), g.getCell(0,3))
// // g.removeWall(g.getCell(3,2), g.getCell(2,2))
// g.printVisited()
// console.log('')
// console.log('Start')
// // g.printWalls()

// // console.log(`Neighours to ${g.neighboursToString(g.getCell(0,0), g.getUnvisitedNeighbours(g.getCell(0,0)))}`)
// // console.log(`Neighours to ${g.neighboursToString(g.getCell(6,2), g.getUnvisitedNeighbours(g.getCell(6,2)))}`)
// // console.log(`Neighours to ${g.neighboursToString(g.getCell(9,3), g.getUnvisitedNeighbours(g.getCell(9,3)))}`)

// let startCell = g.getCell(0,0)
// g.randomizeDFS(startCell)

// const start = g.getCell(0,0)
// start.walls.west = false

// const finish = g.getCell(g.numberOfRows-1, g.numberOfCols-1)
// finish.walls.east = false
// g.printWalls()



