import * as React from 'react'
import Random from './Random'

class Cell {
  row
  col
  radius = 5
  strokeWidth = 1

  constructor(row, col, radius, strokeWidth) {
    this.row = row
    this.col = col
    this.radius = radius
    this.strokeWidth = strokeWidth
  }
}

export default function CirclePattern(props) {
  const widthOfCell = props.width / props.numberOfCellsHorizontally
  const numberOfRows = props.height / widthOfCell
  const numberOfCols = props.width / widthOfCell

  let rows = []
  const rnd = new Random(props.address, 3, 0b111)

  for (let r = 0; r < numberOfRows; r++) {
    const row = []
    for (let c = 0; c < numberOfCols; c++) {
      const radius = rnd.getRandomNumber()
      const strokeWidth = rnd.getRandomNumber()
      row.push(new Cell(r, c, radius, strokeWidth))
    }
    rows.push(row)
  }

  const backgroundColor = props.darkMode ? 'black' : 'white'
  const wallColor = props.darkMode ? 'gray' : 'silver'
  const textColor = props.darkMode ? 'white' : 'black'

  return (
    <svg
      version="1.1"
      width={props.width}
      height={props.height}
      xmlns="http://www.w3.org/2000/svg"
    >
      <style>
        {`

          .pacman-open-small,
          .pacman-mouth-top-small,
          .pacman-mouth-bottom-small {
            fill: gold;
          }

          .pacman-mouth-top-small,
          .pacman-mouth-bottom-small {
            animation-duration: 250ms;
            animation-timing-function: linear;
            animation-direction: alternate;
            animation-iteration-count: infinite;
            transform-origin: 5px 5px; // center of circle
          }

          .pacman-mouth-top-small {
            animation-name: rotate-counterclockwise;
          }

          .pacman-mouth-bottom-small {
            animation-name: rotate-clockwise;
          }


          @keyframes rotate-counterclockwise {
            100% {
                transform: rotate(-30deg);
            }
          }

          @keyframes rotate-clockwise {
            100% {
                transform: rotate(30deg);
            }
          }

          @keyframes dash {
            to {
                stroke-dashoffset: 0;
            }
          }

          @keyframes move {
            0% {
                offset-distance: 0%;
            }

            100% {
                offset-distance: 100%;
            }
          }
            `}
      </style>
      <defs>
        <pattern
          id="smallGrid"
          width={widthOfCell}
          height={widthOfCell}
          patternUnits="userSpaceOnUse"
        ></pattern>
        <pattern
          id="grid"
          width={widthOfCell * 10}
          height={widthOfCell * 10}
          patternUnits="userSpaceOnUse"
        >
          <rect
            width={widthOfCell * 10}
            height={widthOfCell * 10}
            fill="url(#smallGrid)"
          />
          <path
            d={`M ${widthOfCell * 10} 0 L 0 0 0 ${widthOfCell * 10}`}
            fill="none"
            stroke={wallColor}
            strokeWidth="2"
          />
        </pattern>

        <defs>
          <radialGradient
            id="0"
            gradientTransform="translate(-0.9 0) scale(2, 2)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0%" stopColor="#fdd575" />
            <stop offset="14.29%" stopColor="#fcc06a" />
            <stop offset="28.57%" stopColor="#fbaa63" />
            <stop offset="42.86%" stopColor="#f99262" />
            <stop offset="57.14%" stopColor="#f77868" />
            <stop offset="71.43%" stopColor="#f55973" />
            <stop offset="100%" stopColor="#d22e8c" />
          </radialGradient>
          <linearGradient
            id="1"
            x1="0.35"
            y1="0.02"
            x2="0.65"
            y2="0.98"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0%" stopColor="#4964da" />
            <stop offset="11.8%" stopColor="rgba(146, 73, 221, 0.8)" />
            <stop offset="23.6%" stopColor="rgba(176, 60, 196, 0.6)" />
            <stop offset="35.4%" stopColor="rgba(192, 55, 173, 0.4)" />
            <stop offset="59%" stopColor="rgba(217, 46, 116, 0)" />
          </linearGradient>
        </defs>

        <linearGradient
          id="linGrad"
          x1="0"
          x2="100%"
          y1="0"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0%" stopColor="red" />
          <stop offset="100%" stopColor="blue" />
        </linearGradient>

        <linearGradient
          id="gradientViolet2Yellow"
          gradientTransform="rotate(30, 1, 1)"

          x1="0"
          y1="0"
          x2="100%"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#2b0081" />
          <stop offset="0.16666666666666666" stopColor="#860078" />
          <stop offset="0.3333333333333333" stopColor="#bd0069" />
          <stop offset="0.5" stopColor="#e23d59" />
          <stop offset="0.6666666666666666" stopColor="#f7744f" />
          <stop offset="0.8333333333333333" stopColor="#ffa851" />
          <stop offset="1" stopColor="#ffda6a" />
        </linearGradient>
        <linearGradient
          gradientTransform="rotate(118, 0.5, 0.5)"
          x1="50%"
          y1="0%"
          x2="50%"
          y2="100%"
          gradientUnits="userSpaceOnUse"
          id="ffflux-gradient"
        >
          <stop
            stopColor="hsl(37, 91%, 55%)"
            stopOpacity="1"
            offset="0%"
          ></stop>
          <stop
            stopColor="hsl(167, 100%, 50%)"
            stopOpacity="1"
            offset="100%"
          ></stop>
        </linearGradient>
      </defs>

      {/* Background */}
      <rect width="100%" height="100%" fill={backgroundColor} stroke="none" />

      {/* Grid */}
      <rect
        width="100%"
        height="100%"
        fill="url(#grid)"
        display={props.grid ? 'inline' : 'none'}
      />

      <g>
        {rows.flat(2).map((c, circleIndex) => (
          <g key={`group${circleIndex}`}>
            {/* <circle
              key={`circle-a${circleIndex}`}
              cx={c.col * widthOfCell + widthOfCell / 2}
              cy={c.row * widthOfCell + widthOfCell / 2}
              r={c.radius}
              strokeWidth={c.strokeWidth}
              fill="url(#0)"
            /> */}

            <circle
              key={`circle-b${circleIndex}`}
              cx={c.col * widthOfCell + widthOfCell / 2}
              cy={c.row * widthOfCell + widthOfCell / 2}
              r={c.radius}
              strokeWidth={c.strokeWidth}
              fill="url(#gradientViolet2Yellow)"
            />
          </g>
        ))}
      </g>
    </svg>
  )
}
