/* global BigInt */
import { ethers } from 'ethers'

export default class Random {
  
  randomValueParams = {
    seed: 0n,
    shiftWidth: 2n,
    shiftIndex: 0n,
    bitmask: 0b11n,
  }

  constructor(randomSeed, shiftWidth, bitmask) {
    console.log('Setup random')
    this.randomValueParams.seed = BigInt(randomSeed)
    this.randomValueParams.shiftWidth = BigInt(shiftWidth)
    this.randomValueParams.bitmask = BigInt(bitmask)

    console.log(this.randomValueParams)
  }


  getRandomNumber = () => {
    let { seed, shiftWidth, shiftIndex, bitmask } = this.randomValueParams
    const shiftedValue = seed >> (shiftWidth * shiftIndex)
    const randomValue = Number(shiftedValue & bitmask)
    shiftIndex++
    if (shiftIndex * shiftWidth >= seed.toString(2).length) {
      shiftIndex = 0n
      seed = BigInt(ethers.id(seed.toString(16)))
    }
    this.randomValueParams.shiftIndex = shiftIndex
    this.randomValueParams.seed = seed
    return randomValue
  }
}


