import * as React from 'react';
import { Button, Link, Typography } from '@mui/material';

export default function Copyright() {
  return (
    // <Typography variant="body1">
    //   {'© '}
    //   <Link color="inherit" href="https://laidback.ventures/">
    //     Laid Back Ventures
    //   </Link>{' '}
    //   {new Date().getFullYear()}
    // </Typography>
    <Button color='inherit' href='https://laidback.ventures'>© Laid Back Ventures {new Date().getFullYear()}</Button>
  );
}