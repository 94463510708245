/* global BigInt */
import * as React from 'react'
import Maze from './Maze'

export default function Tattoo(props) {
  const changeSingleCharacter = (character) => {
    const substitutions = new Map()
    substitutions.set('1', 'I')
    substitutions.set('2', 'II')
    substitutions.set('3', 'III')
    substitutions.set('4', 'IV')
    substitutions.set('5', 'V')
    substitutions.set('6', 'VI')
    substitutions.set('7', 'VII')
    substitutions.set('8', 'VIII')
    substitutions.set('9', 'IX')

    return substitutions.has(character)
      ? substitutions.get(character)
      : character
  }

  const getRomanNumberals = (hexString, numberOfChars) => {
    return hexString
      .slice(numberOfChars * -1)
      .split('')
      .map(changeSingleCharacter)
      .join(' ')
  }

  const drawWalls = (cell, cellWidth) => {
    const x = cell.col * cellWidth
    const y = cell.row * cellWidth

    const walls = []
    cell.walls.north && walls.push([x, y, cellWidth, 0])
    cell.walls.east && walls.push([x + cellWidth, y, 0, cellWidth])
    cell.walls.south && walls.push([x, y + cellWidth, cellWidth, 0])
    cell.walls.west && walls.push([x, y, 0, cellWidth])

    return walls
  }

  const widthOfCell = props.width / props.numberOfCellsHorizontally
  const numberOfRows = props.height / widthOfCell
  const numberOfCols = props.width / widthOfCell

  console.log(
    `Maze will be ${numberOfRows} x ${numberOfCols} (rows x cols) = ${
      numberOfRows * numberOfCols
    } cells.`,
  )

  const maze = new Maze(numberOfRows, numberOfCols, props.address)

  console.log('Maze initialized')
  //   maze.printWalls()
  const startCell = maze.getCell(0, 0)
  maze.randomizeDFS(startCell)
  //   maze.printWalls()

  const walls = maze.rows.map((r) => {
    return r.map((c) => {
      return drawWalls(c, widthOfCell)
    })
  })

  const solvedCells = maze.solution
  const lengthOfSolutionPath = solvedCells.length * widthOfCell

  const solvedCenterPoints = solvedCells.map((c) => ({
    x: c.col * widthOfCell + widthOfCell / 2,
    y: c.row * widthOfCell + widthOfCell / 2,
  }))
  const solutionPath = `M ${solvedCenterPoints[0].x} ${
    solvedCenterPoints[0].y
  } ${solvedCenterPoints.slice(1).map((c) => ['L', c.x, c.y].join(' '))}`

  const backgroundColor = props.darkMode ? 'black' : 'white'
  const wallColor = props.darkMode ? 'gray' : 'silver'
  const textColor = props.darkMode ? 'white' : 'black'
  const binaryString = new Array(30)
    .fill(
      BigInt(props.address)
        .toString(2)
        .replaceAll('1', '①')
        .replaceAll('0', '⓿'),
    )
    .join('')

  const centerPts = maze.rows.map((r) => {
    return r.map((c) => {
      return {
        x: c.col * widthOfCell + widthOfCell / 2,
        y: c.row * widthOfCell + widthOfCell / 2,
      }
    })
  })

  return (
    <svg
      version="1.1"
      width={props.width}
      height={props.height}
      xmlns="http://www.w3.org/2000/svg"
    >
      <style>
        {`
          .animated {
            animation: dash 120s linear alternate infinite;
            stroke-dasharray: ${lengthOfSolutionPath};
            stroke-dashoffset: ${lengthOfSolutionPath};
          }

          .pacman-line {
            animation: dash 120s linear;
            stroke-dasharray: ${lengthOfSolutionPath};
            stroke-dashoffset: ${lengthOfSolutionPath};
          }

          .moving {
            animation: move 120s linear infinite;
            offset-path: path("${solutionPath}");
          }

          .pacman-open-small,
          .pacman-mouth-top-small,
          .pacman-mouth-bottom-small {
            fill: gold;
          }

          .pacman-mouth-top-small,
          .pacman-mouth-bottom-small {
            animation-duration: 250ms;
            animation-timing-function: linear;
            animation-direction: alternate;
            animation-iteration-count: infinite;
            transform-origin: 5px 5px; // center of circle
          }

          .pacman-mouth-top-small {
            animation-name: rotate-counterclockwise;
          }

          .pacman-mouth-bottom-small {
            animation-name: rotate-clockwise;
          }


          @keyframes rotate-counterclockwise {
            100% {
                transform: rotate(-30deg);
            }
          }

          @keyframes rotate-clockwise {
            100% {
                transform: rotate(30deg);
            }
          }

          @keyframes dash {
            to {
                stroke-dashoffset: 0;
            }
          }

          @keyframes move {
            0% {
                offset-distance: 0%;
            }

            100% {
                offset-distance: 100%;
            }
          }
            `}
      </style>
      <defs>
        <pattern
          id="smallGrid"
          width={widthOfCell}
          height={widthOfCell}
          patternUnits="userSpaceOnUse"
        ></pattern>
        <pattern
          id="grid"
          width={widthOfCell * 10}
          height={widthOfCell * 10}
          patternUnits="userSpaceOnUse"
        >
          <rect
            width={widthOfCell * 10}
            height={widthOfCell * 10}
            fill="url(#smallGrid)"
          />
          <path
            d={`M ${widthOfCell * 10} 0 L 0 0 0 ${widthOfCell * 10}`}
            fill="none"
            stroke={wallColor}
            strokeWidth="2"
          />
        </pattern>

        <linearGradient
          gradientTransform="rotate(118, 0.5, 0.5)"
          x1="50%"
          y1="0%"
          x2="50%"
          y2="100%"
          id="ffflux-gradient"
        >
          <stop
            stopColor="hsl(37, 91%, 55%)"
            stopOpacity="1"
            offset="0%"
          ></stop>
          <stop
            stopColor="hsl(167, 100%, 50%)"
            stopOpacity="1"
            offset="100%"
          ></stop>
        </linearGradient>
        <filter
          id="ffflux-filter"
          x="-20%"
          y="-20%"
          width="140%"
          height="140%"
          filterUnits="objectBoundingBox"
          primitiveUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feTurbulence
            type="fractalNoise"
            baseFrequency="0.005 0.003"
            numOctaves="2"
            seed="74"
            stitchTiles="stitch"
            x="0%"
            y="0%"
            width="100%"
            height="100%"
            result="turbulence"
          ></feTurbulence>
          <feGaussianBlur
            stdDeviation="20 0"
            x="0%"
            y="0%"
            width="100%"
            height="100%"
            in="turbulence"
            edgeMode="duplicate"
            result="blur"
          ></feGaussianBlur>
          <feBlend
            mode="color-burn"
            x="0%"
            y="0%"
            width="100%"
            height="100%"
            in="SourceGraphic"
            in2="blur"
            result="blend"
          ></feBlend>
        </filter>
      </defs>

      {/* Background */}
      <rect width="100%" height="100%" fill={backgroundColor} stroke="none" />

      {/* Grid */}
      <rect
        width="100%"
        height="100%"
        fill="url(#grid)"
        display={props.grid ? 'inline' : 'none'}
      />

      {/* Walls */}
      {walls.flat(2).map((w, index) => (
        <path
          key={`wall${index}-${w[0]}/${w[1]}`}
          d={`M ${w[0]} ${w[1]} l ${w[2]} ${w[3]}`}
          stroke={wallColor}
          strokeWidth="2"
          strokeLinecap="round"
          fill="none"
        />
      ))}

      {/* {solvedCells.map((c) => (
        <rect
        x={c.col * widthOfCell}
          y={c.row * widthOfCell}
          width={widthOfCell}
          height={widthOfCell}
          fill="lightgreen"
          />
        ))} */}

      {/* Animated, colored path */}
      <path
        display={props.path ? 'inline' : 'none'}
        className="animated"
        d={solutionPath}
        filter={props.filter ? 'url(#ffflux-filter)' : 'none'}
        stroke="url(#ffflux-gradient)"
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeWidth={widthOfCell - 6}
        fill="none"
      />

      {/* Heading circle for path */}
      <circle
        display={props.path ? 'inline' : 'none'}
        r="5"
        fill="red"
        className="moving"
      />

      {/* Text path */}
      <path
        id="textPath"
        d={solutionPath}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeWidth={widthOfCell - 4}
        stroke="none"
        fill="none"
      />

      <text
        className="animated"
        display={props.textPath ? 'inline' : 'none'}
        dominantBaseline="middle"
        fill="url(#ffflux-gradient)"
        fontWeight="bold"
        fontFamily="Optima, Helvetica, sans-serif"
        fontSize="xx-small"
      >
        <textPath href="#textPath" className="animated">
          {binaryString}
        </textPath>
      </text>

      {/* Pac Man Pts. */}
      <g fill="silver" display={props.pacman ? 'inline' : 'none'}>
        {centerPts.flat(2).map((pt) => (
          <circle key={`dot${pt.x}/${pt.y}`}r="2" cx={pt.x} cy={pt.y} />
        ))}
      </g>

      {/* Pac Man hide pts. line */}
      <path
        display={props.pacman ? 'inline' : 'none'}
        className={'pacman-line'}
        d={solutionPath}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeWidth={widthOfCell - 6}
        stroke={backgroundColor}
        fill="none"
      />

      {/* Animated, colored path */}
      {/* <path
        display={props.path ? 'inline' : 'none'}
        className="animated"
        d={solutionPath}
        filter={props.filter ? 'url(#ffflux-filter)' : 'none'}
        stroke="url(#ffflux-gradient)"
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeWidth={widthOfCell - 6}
        fill="none"
      /> */}

      {/* Pac Man */}
      <g
        display={props.pacman ? 'inline' : 'none'}
        transform="translate(-5, -5)"
        className="moving"
      >
        <path
          className="pacman-mouth-bottom-small"
          d="M 5 5 l 5 0 a 5 5 0 0 1 -2 4 Z"
        />
        <path
          className="pacman-mouth-top-small"
          d="M 5 5 l 3 -4 a 5 5 0 0 1 2 4 Z"
        />
        <path
          className="pacman-open-small"
          d="M 5 5 l 4 -3 a 5 5 0 1 0 0 6 Z"
        />
      </g>

      {/* Roman Numerals */}
      <text
        x="50%"
        y="50%"
        fontSize={props.width / 10}
        textAnchor="middle"
        dominantBaseline="middle"
        fill={textColor}
        // fill="url(#ffflux-gradient)"
        fontFamily="palatino, serif"
        letterSpacing={-10}
      >
        {getRomanNumberals(props.address, props.numberOfChars)}
      </text>
    </svg>
  )
}
