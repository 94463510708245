import { Button, IconButton, Link, Stack } from '@mui/material'
import * as React from 'react'

import Copyright from './Copyright'

export default function Footer(props) {
  return (
    <Stack
      sx={{ backgroundColor: 'grey.500' }}
      p={2}
      direction="row"
      justifyContent="space-between"
    >
      <Copyright />
    </Stack>
  )
}
